import React, {FC} from 'react'
import {Document, Page, StyleSheet, Text, Font, View} from '@react-pdf/renderer'
import Title from './common/Title'
import {config} from './common/config'
import Subtext from './common/Subtext'
import {IClientsData} from '../../../models/client'
import Row from './common/Row'
import SectionHeader from './common/SectionHeader'
import Field from './common/Field'
import Label from './common/Label'
import Table from './common/Table'
import ListText from './components/ListText'
import {paymentCycleOptions} from '../../../constant/date'

Font.register({
  family: 'Poppins',
  fonts: [{src: 'https://cdn.jsdelivr.net/gh/webfontworld/Poppins/Poppins-Regular.ttf'}],
})

const styles = StyleSheet.create({
  page: {
    padding: 40,
    paddingTop: 50,
    paddingRight: 50,
    fontFamily: config.fontFamily,
    fontSize: config.normalFontSize,
  },
  subtext: {
    marginBottom: 10,
  },
  signatures: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  signature: {
    width: '150px',
    paddingTop: '10px',
    borderTopWidth: 2,
    borderTopColor: config.primaryColor,
    borderTopStyle: 'solid',
    textAlign: 'center',
    fontSize: 12,
  },
  header: {
    fontWeight: 'bold',
    marginBottom: '5px',
  },
})

interface IProps {
  formData: IClientsData
}

const DirectDebitTestPDF: FC<IProps> = ({formData}) => {
  const formattedDate = () => {
    const paymentCycleStartDate = formData?.paymentCycleStartDate || new Date()
    const options = {day: 'numeric', month: 'long', year: 'numeric'} as const
    const dateParts = new Date(paymentCycleStartDate)
      .toLocaleDateString('en-GB', options)
      .split(' ')
    const formattedDate = `${dateParts[0]}-${dateParts[1]}-${dateParts[2]}`

    return formattedDate
  }

  return (
    <Document>
      <Page size='A4' style={styles.page} wrap>
        <Title>Direct Debit Agreement</Title>

        <Field>
          'ABC Pty Ltd uses Zai Australia Pty Ltd. to process Direct Debits on its behalf.
        </Field>
        <Field>
          This is your Direct Debit Request Service Agreement with Zai Australia Pty. Ltd., (User
          ID’s 342203 and 481561) & ABN 96637632645. It explains what your obligations are when
          undertaking a Direct Debit arrangement with us. It also details what our obligations are
          to you as your Direct Debit provider.
        </Field>
        <Field>
          Please keep this agreement for future reference. It forms part of the terms and conditions
          of your Direct Debit Request (DDR) and should be read in conjunction with your DDR
          authorisation.
        </Field>

        <SectionHeader>Definitions</SectionHeader>
        <ListText style={{marginBottom: 8}} listTypeText='•' boldLabel='account'>
          {'\u00A0'}means the account held at your financial institution from which we are
          authorised to arrange for funds to be debited.
        </ListText>
        <ListText style={{marginBottom: 4}} listTypeText='•' boldLabel='agreement'>
          {'\u00A0'}means this Direct Debit Request Service Agreement between you and us.
        </ListText>
        <ListText style={{marginBottom: 8}} listTypeText='•' boldLabel='banking day'>
          {'\u00A0'}means a day other than a Saturday or a Sunday or a public holiday listed
          throughout Australia.
        </ListText>
        <ListText style={{marginBottom: 4}} listTypeText='•' boldLabel='debit day'>
          {'\u00A0'}means the day that payment by you to us is due.
        </ListText>
        <ListText style={{marginBottom: 4}} listTypeText='•' boldLabel='debit payment'>
          {'\u00A0'}means a particular transaction where a debit is made.
        </ListText>
        <View
          style={{
            flexDirection: 'row',
            fontFamily: config.fontFamily,
            fontSize: config.normalFontSize + 1,
            marginBottom: 8,
            flex: 1,
            width: '100%',
          }}
        >
          <Text style={{marginHorizontal: 8}}>•</Text>
          <Text
            style={{
              fontWeight: 'bold',
              fontSize: config.normalFontSize + 1,
              marginRight: 5,
            }}
          >
            us
            <Text
              style={{
                color: config.grayColor,
                fontSize: config.normalFontSize,
              }}
            >
              {'\u00A0'}or{'\u00A0'}
            </Text>
            <Text
              style={{
                fontWeight: 'bold',
                fontSize: config.normalFontSize + 1,
                marginRight: 5,
              }}
            >
              we
              <Text
                style={{
                  color: config.grayColor,
                  fontSize: config.normalFontSize,
                }}
              >
                {'\u00A0'}means{'\u00A0'}
              </Text>
              <Text
                style={{
                  fontWeight: 'bold',
                  fontSize: config.normalFontSize + 1,
                  marginRight: 15,
                }}
              >
                Zai Australia Pty. Ltd. trading as Zai
                <Text
                  style={{
                    color: config.grayColor,
                    fontSize: config.normalFontSize,
                  }}
                >
                  {'\u00A0'}(the Debit User) you have authorised by requesting a Direct Debit
                  Request.
                </Text>
              </Text>
            </Text>
          </Text>
        </View>
        <ListText style={{marginBottom: 4}} listTypeText='•' boldLabel='you'>
          {'\u00A0'}means the customer who has signed or authorised by other means the Direct Debit
          Request.
        </ListText>
        <ListText style={{marginBottom: 8}} listTypeText='•' boldLabel='your financial institution'>
          {'\u00A0'}means the financial institution nominated by you on the DDR at which the account
          is maintained.
        </ListText>

        <SectionHeader>Debiting your account</SectionHeader>
        <ListText style={{marginBottom: 12}} listTypeText='1.'>
          By signing a Direct Debit Request or by providing us with a valid instruction, you have
          authorised us to arrange for funds to be debited from your account. You should refer to
          the Direct Debit Request and this agreement for the terms of the arrangement between us
          and you.
        </ListText>
        <View
          style={{
            flexDirection: 'row',
            fontFamily: config.fontFamily,
            fontSize: config.normalFontSize + 1,
            marginBottom: 8,
            width: '100%',
          }}
        >
          <Text style={{marginHorizontal: 8}}>2.</Text>
          <View>
            <Text
              style={{
                color: config.grayColor,
                fontSize: config.normalFontSize,
                marginBottom: 4,
              }}
            >
              We will only arrange for funds to be debited from your account as authorised in the
              Direct Debit Request.
            </Text>
            <Text
              style={{
                fontWeight: 'bold',
                fontSize: config.normalFontSize + 1,
                marginBottom: 4,
              }}
            >
              or
            </Text>
            <Text
              style={{
                color: config.grayColor,
                fontSize: config.normalFontSize,
              }}
            >
              We will only arrange for funds to be debited from your account if we have sent to the
              address nominated by you in the Direct Debit Request, a billing advice which specifies
              the amount payable by you to us and when it is due.
            </Text>
          </View>
        </View>
        <ListText style={{marginBottom: 12}} listTypeText='3.'>
          If the debit day falls on a day that is not a banking day, we may direct your financial
          institution to debit your account on the following banking day. If you are unsure about
          which day your account has or will be debited you should ask your financial institution.
        </ListText>

        <SectionHeader>Amendments by us</SectionHeader>
        <Field>
          We may vary any details of this agreement or a Direct Debit Request at any time by giving
          you at least fourteen (14) days written notice.
        </Field>

        <SectionHeader>Amendments by you</SectionHeader>
        <Field>
          You may change*, stop or defer a debit payment, or terminate this agreement by providing
          us with at least fourteen (14 days) notification by writing to:
        </Field>
        <Field
          style={{
            fontWeight: 'bold',
            fontSize: config.normalFontSize + 1,
          }}
          label='Accounts Department'
        />
        <Field
          style={{
            fontWeight: 'bold',
            fontSize: config.normalFontSize + 1,
          }}
          label='Zai'
        />
        <Field
          style={{
            fontWeight: 'bold',
            fontSize: config.normalFontSize + 1,
          }}
          label='Level 18,'
        />
        <Field
          style={{
            fontWeight: 'bold',
            fontSize: config.normalFontSize + 1,
          }}
          label='120 Spencer Street,'
        />
        <Field
          style={{
            fontWeight: 'bold',
            fontSize: config.normalFontSize + 1,
          }}
          label='Melbourne VIC 3000'
        />
        <Field
          style={{
            fontWeight: 'bold',
            fontSize: config.normalFontSize + 1,
          }}
          label='Australia'
        />
        <Field>
          or by emailing us at{'\u00A0'}
          <a style={{color: 'blue'}} href='mailto:helpdesk@hellozai.com'>
            helpdesk@hellozai.com
          </a>
          {'\u00A0'}
          during business hours; or arranging it through your own financial institution, which is
          required to act promptly on your instructions.
        </Field>
        <Field>
          *Note: in relation to the above reference to ‘change’, your financial institution may
          ‘change’ your debit payment only to the extent of advising us of your new account details.
        </Field>

        <SectionHeader>Your obligations</SectionHeader>
        <Field>
          It is your responsibility to ensure that there are sufficient clear funds available in
          your account to allow a debit payment to be made in accordance with the Direct Debit
          Request.
        </Field>
        <Field>
          If there are insufficient clear funds in your account to meet a debit payment:
        </Field>
        <ListText style={{marginBottom: 4}} listTypeText='(a)'>
          you may be charged a fee and/or interest by your financial institution;
        </ListText>
        <ListText style={{marginBottom: 4}} listTypeText='(b)'>
          you may also incur fees or charges imposed or incurred by us; and
        </ListText>
        <ListText style={{marginBottom: 8}} listTypeText='(c)'>
          you must arrange for the debit payment to be made by another method or arrange for
          sufficient clear funds to be in your account by an agreed time so that we can process the
          debit payment.
        </ListText>
        <Field>
          You should check your account statement to verify that the amounts debited from your
          account are correct
        </Field>

        <SectionHeader>Dispute</SectionHeader>
        <ListText style={{marginBottom: 12}} listTypeText='1.'>
          If you believe that there has been an error in debiting your account, you should notify us
          directly at{'\u00A0'}
          <a style={{color: 'blue'}} href='mailto:helpdesk@hellozai.com'>
            helpdesk@hellozai.com
          </a>
          {'\u00A0'}
          and confirm that notice in writing with us as soon as possible so that we can resolve your
          query more quickly. Alternatively, you can take it up directly with your financial
          institution.
        </ListText>
        <ListText style={{marginBottom: 12}} listTypeText='2.'>
          If we conclude as a result of our investigations that your account has been incorrectly
          debited we will respond to your query by arranging for your financial institution to
          adjust your account (including interest and charges) accordingly. We will also notify you
          in writing of the amount by which your account has been adjusted.
        </ListText>
        <ListText style={{marginBottom: 12}} listTypeText='3.'>
          If we conclude as a result of our investigations that your account has not been
          incorrectly debited we will respond to your query by providing you with reasons and any
          evidence for this finding in writing.
        </ListText>

        <SectionHeader>Accounts</SectionHeader>
        <Field>You should check:</Field>
        <ListText style={{marginBottom: 8}} listTypeText='1.'>
          with your financial institution whether direct debiting is available from your account as
          direct debiting is not available on all accounts offered by financial institutions.
        </ListText>
        <ListText style={{marginBottom: 8}} listTypeText='2.'>
          your account details which you have provided to us are correct by checking them against a
          recent account statement; and
        </ListText>
        <ListText style={{marginBottom: 8}} listTypeText='3.'>
          with your financial institution before completing the Direct Debit Request if you have any
          queries about how to complete the Direct Debit Request.
        </ListText>

        <SectionHeader>Confidentiality</SectionHeader>
        <ListText style={{marginBottom: 12}} listTypeText='1.'>
          We will keep any information (including your account details) in your Direct Debit Request
          confidential. We will make reasonable efforts to keep any such information that we have
          about you secure and to ensure that any of our employees or agents who have access to
          information about you do not make any unauthorised use, modification, reproduction, or
          disclosure of that information.
        </ListText>
        <ListText style={{marginBottom: 4}} listTypeText='2.'>
          We will only disclose information that we have about you:
        </ListText>
        <ListText style={{marginBottom: 4, marginLeft: 8}} listTypeText='a.'>
          to the extent specifically required by law;
        </ListText>
        <ListText style={{marginBottom: 4, marginLeft: 8}} listTypeText='b.'>
          or for the purposes of this agreement (including disclosing information in connection with
          any query or claim).
        </ListText>

        <SectionHeader>Notice</SectionHeader>
        <Field>
          If you wish to notify us in writing about anything relating to this agreement, you should
          write to
        </Field>
        <Field
          style={{
            fontWeight: 'bold',
            fontSize: config.normalFontSize + 1,
          }}
          label='Accounts Department'
        />
        <Field
          style={{
            fontWeight: 'bold',
            fontSize: config.normalFontSize + 1,
          }}
          label='Zai'
        />
        <Field
          style={{
            fontWeight: 'bold',
            fontSize: config.normalFontSize + 1,
          }}
          label='Level 18,'
        />
        <Field
          style={{
            fontWeight: 'bold',
            fontSize: config.normalFontSize + 1,
          }}
          label='120 Spencer Street,'
        />
        <Field
          style={{
            fontWeight: 'bold',
            fontSize: config.normalFontSize + 1,
          }}
          label='Melbourne VIC 3000'
        />
        <Field
          style={{
            fontWeight: 'bold',
            fontSize: config.normalFontSize + 1,
          }}
          label='Australia'
        />
        <Field>
          <a style={{color: 'blue'}} href='mailto:helpdesk@hellozai.com'>
            helpdesk@hellozai.com
          </a>
        </Field>
        <Field>
          We will notify you by sending a notice in the ordinary post to the address you have given
          us in the Direct Debit Request.
        </Field>
        <Field>
          Any notice will be deemed to have been received on the third banking day after posting.
        </Field>

        <SectionHeader>
          Request and Authority to debit the account named below to pay Zai Australia Pty. Ltd.
          trading as Zai.
        </SectionHeader>
        <Field>Request and Authority to debit</Field>
        <Row style={{marginBottom: 12}}>
          <Label>Your Surname or company name</Label>
          <Label style={{color: 'blue'}}>
            {'\u00A0'}
            {formData?.lastName}
          </Label>
        </Row>
        <Row style={{marginBottom: 12}}>
          <Label>Your Given names or ABN/ARBN</Label>
          <Label style={{color: 'blue'}}>
            {'\u00A0'}
            {formData?.firstName}
          </Label>
        </Row>
        <View
          style={{
            flexDirection: 'row',
            fontFamily: config.fontFamily,
          }}
        >
          <Text
            style={{
              color: config.grayColor,
              fontSize: config.normalFontSize,
              marginRight: 5,
            }}
          >
            request and authorise
            <Text
              style={{
                color: 'black',
                fontWeight: 'bold',
                fontSize: config.normalFontSize + 1,
              }}
            >
              {'\u00A0'}Zai Australia Pty. Ltd. trading as Zai & User ID's 342203 and 481561
              {'\u00A0'}
            </Text>
            <Text
              style={{
                color: config.grayColor,
                fontSize: config.normalFontSize,
                marginRight: 5,
              }}
            >
              to arrange, through its own financial institution, a debit to your nominated account
              any amount
              <Text
                style={{
                  color: 'black',
                  fontWeight: 'bold',
                  fontSize: config.normalFontSize + 1,
                }}
              >
                {'\u00A0'}Zai Australia Pty. Ltd. trading as Zai
              </Text>
              <Text
                style={{
                  color: config.grayColor,
                  fontSize: config.normalFontSize,
                }}
              >
                , has deemed payable by you. You acknowledge that Zai Australia Pty. Ltd. trading as
                Zai has different user id’s depending on the financial institution it uses.
              </Text>
            </Text>
          </Text>
        </View>
        <Field>
          This debit or charge will be made through the Bulk Electronic Clearing System (BECS) from
          your account held at the financial institution you have nominated below and will be
          subject to the terms and conditions of the Direct Debit Request Service Agreement.
        </Field>

        <SectionHeader>Financial details</SectionHeader>
        <Row style={{marginBottom: 12}}>
          <Label>Financial institution name</Label>
          <Label style={{color: 'blue'}}>
            {'\u00A0'}
            {formData?.bankName ?? formData?.banks[0]?.bankName}
          </Label>
        </Row>
        <Row style={{marginBottom: 12}}>
          <Label>Address</Label>
          <Label style={{color: 'blue'}}>
            {'\u00A0'}
            {formData?.bankBranch ?? formData?.banks[0]?.bankBranch}
          </Label>
        </Row>
        <Row style={{marginBottom: 12}}>
          <Label>Name/s on account</Label>
          <Label style={{color: 'blue'}}>
            {'\u00A0'}
            {formData?.bankAccountName ?? formData?.banks[0]?.bankAccountName}
          </Label>
        </Row>
        <Row style={{marginBottom: 12}}>
          <Label>BSB number (Must be 6 Digits) </Label>
          <Label style={{color: 'blue'}}>
            {'\u00A0'}
            {formData?.bsb1 && formData?.bsb1
              ? formData?.bsb1 + '-' + formData?.bsb2
              : formData?.banks[0]?.bsb1 + '-' + formData?.banks[0]?.bsb2}
          </Label>
        </Row>
        <Row style={{marginBottom: 12}}>
          <Label>Account number</Label>
          <Label style={{color: 'blue'}}>
            {'\u00A0'}
            {formData?.bankAccountNumber ?? formData?.banks[0]?.bankAccountNumber}
          </Label>
        </Row>
        <Row style={{marginBottom: 12}}>
          <Label>Direct Debit Amount</Label>
          <Label style={{color: 'blue'}}>
            {'\u00A0'}$ {formData?.debitAmount?.toFixed(2)}
          </Label>
        </Row>
        <Row style={{marginBottom: 12}}>
          <Label>Payment Cycle </Label>
          <Label style={{color: 'blue'}}>
            {'\u00A0'}
            {
              paymentCycleOptions?.find(
                (option) => option.value === formData?.paymentCycle?.toString()
              )?.label
            }
          </Label>
        </Row>
        <Row style={{marginBottom: 12}}>
          <Label>Start Date </Label>
          <Label style={{color: 'blue'}}>
            {'\u00A0'}
            {formattedDate()}
          </Label>
        </Row>

        <SectionHeader>Acknowledgment</SectionHeader>
        <Field>
          By signing and/or providing us with a valid instruction in respect to your Direct Debit
          Request, you have understood and agreed to the terms and conditions governing the debit
          arrangements between you and Zai Australia Pty. Ltd. trading as Zai as set out in this
          Request and in your Direct Debit Request Service Agreement.
        </Field>
      </Page>
    </Document>
  )
}

export default DirectDebitTestPDF
