import {FC, Fragment} from 'react'
import {useIntl} from 'react-intl'

import Wrapper from './components/Wrapper'
import {ContextProvider} from './context'
import {UserModel} from '../auth/models/UserModel'
import {RootState} from '../../../setup'
import {shallowEqual, useSelector} from 'react-redux'
import {toAbsoluteUrl} from '../../../_metronic/helpers'

const Page: FC = () => {
  const intl = useIntl()
  const user: UserModel = useSelector<RootState>(({auth}) => auth.user, shallowEqual) as UserModel

  return (
    <Fragment>
      <div className='d-flex align-items-center mb-8 px-4 py-2 bg-light-primary rounded border-primary border border-dashed'>
        <img
          alt='Disclaimer'
          src={toAbsoluteUrl('/media/svg/misc/exclamation-warning-triangle-icon.svg')}
          className='h-30px'
        />
        <div className='ms-4 mt-4'>
          <h4>
            All queries about payments should be directed to{' '}
            <ins>
              <a href='mailto:ricardoreadingmouse@teachers.org' className='text-dark'>
                ricardoreadingmouse@teachers.org
              </a>
            </ins>
          </h4>
          <p>
            If you have any questions or wish to amend your payment with ABC Pty Ltd, please contact
            us via email
            <ins>
              <a href='mailto:ricardoreadingmouse@teachers.org' className='text-dark'>
                ricardoreadingmouse@teachers.org
              </a>
            </ins>
          </p>
        </div>
      </div>
      <h1>
        {intl.formatMessage({id: 'ECOMMERCE.FIELD.WELCOME'})}{' '}
        <span className='text-uppercase'>{user.firstName}</span>{' '}
        <span className='text-uppercase'>{user.lastName}</span>!
      </h1>
      <h4 className='text-decoration-underline'>{user.email}</h4>
      <ContextProvider>
        <Wrapper />
      </ContextProvider>
    </Fragment>
  )
}

export default Page
